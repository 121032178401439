import React from "react";
import SellersTable from "../components/SellersTable";
import TablePagination from "../components/TablePagination";

const Sellers = () => {
  return (
    <>
      <main>
        <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'>
          <div className='px-4 py-4 sm:px-0 '>
            <SellersTable />
            <TablePagination />
          </div>
        </div>
      </main>
      {/*}
      {loading ? (
        <Loading />
      ) : sellers.length > 0 ? (
        <main>
          <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'>
            <div className='px-4 py-4 sm:px-0 '>
              <SellersTableFilters filters={filters} setFilters={setFilters} />
              <SellersTable />
              <TablePagination total={total} />
            </div>
          </div>
        </main>
      ) : (
        <>
          <div className='flex justify-center items-center flex-col flex-1'>
            <OutlineIcons.XCircle className='h-32 text-gray-400' />
            <p className='text-2xl mt-3 text-gray-400'>No sellers</p>
            <Link
              to='/scraper'
              className='px-3 py-2 mt-5 rounded-md text-sm font-medium text-white bg-gray-600 focus:outline-none'
            >
              Scrape Amazon{" "}
              <OutlineIcons.ArrowNarrowRight className='inline h-4' />
            </Link>
          </div>
        </>
      )}
      */}
    </>
  );
};

export default Sellers;
