import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getScrapeStatusAsync } from "../features/scraper/scraperSlice";
import AnimatedNumber from "animated-number-react";
import * as SolidIcons from "../assets/solid";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import ScrapesMonthlyReport from "../components/scraper/ScrapesMonthlyReport";
import ScrapesHistory from "../components/scraper/ScrapesHistory";
import * as numeral from "numeral";
import Loading from "../components/Loading";

const Scraper = () => {
  const dispatch = useDispatch();
  const {
    loading,
    runningSessionSellersCrawled,
    sellersNotCrawled,
    sessionRunning,
    sellersBlocked,
    sellersSkipped,
    sellersFound,
  } = useSelector((state) => state.scraper);

  const calculatePercentage = (number) => {
    return 0 === sellersNotCrawled
      ? 0
      : ((100 * number) / sellersNotCrawled).toFixed(2);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getScrapeStatusAsync());
    }, 3000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <main>
      <div className='max-w-7xl mx-auto py-6 sm:px-6 lg:px-8'>
        <div className='px-4 py-4 sm:px-0'>
          {sessionRunning && (
            <div className='mb-5'>
              <h3 className='text-lg leading-6 font-medium text-gray-900 '>
                Currently running session:
              </h3>
              <div className='mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3'>
                <div>
                  <div className='px-4 py-5 sm:p-6'>
                    <dl>
                      <dt className='text-base leading-6 font-normal text-gray-900'>
                        Sellers scraped:
                        <Tippy content='Number of new sellers added to the database.'>
                          <button>
                            <SolidIcons.QuestionMarkCircle className='h-4 inline ml-1 mb-1' />
                          </button>
                        </Tippy>
                      </dt>
                      <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
                        <div className='flex items-baseline text-2xl leading-8 font-semibold text-green-600'>
                          <AnimatedNumber
                            value={runningSessionSellersCrawled}
                            formatValue={(value) =>
                              numeral(value).format("0,0")
                            }
                          />
                          <span className='ml-2 text-sm leading-5 font-medium text-gray-500'>
                            of {numeral(sellersNotCrawled).format("0,0")}
                            <Tippy content='This is the amount of sellers left to scrape. It may decrease due to blocked requests.'>
                              <button>
                                <SolidIcons.QuestionMarkCircle className='h-4 inline ml-1 mb-1' />
                              </button>
                            </Tippy>
                          </span>
                        </div>
                        <div className='inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800'>
                          <SolidIcons.Refresh className='h-4 animate-spin m-1' />
                          <span className='sr-only'>Progress</span>
                          {calculatePercentage(runningSessionSellersCrawled)}%
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div className='border-t border-gray-200 md:border-0 md:border-l'>
                  <div className='px-4 py-5 sm:p-6'>
                    <dl>
                      <dt className='text-base leading-6 font-normal text-gray-900'>
                        Sellers skipped
                        <Tippy content='Number of sellers found that had already been crawled and are therefore skipped.'>
                          <button>
                            <SolidIcons.QuestionMarkCircle className='h-4 inline ml-1 mb-1' />
                          </button>
                        </Tippy>
                      </dt>
                      <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
                        <div className='flex items-baseline text-2xl leading-8 font-semibold text-blue-400'>
                          <AnimatedNumber
                            value={sellersSkipped}
                            formatValue={(value) =>
                              numeral(value).format("0,0")
                            }
                          />
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div className='border-t border-gray-200 md:border-0 md:border-l'>
                  <div className='px-4 py-5 sm:p-6'>
                    <dl>
                      <dt className='text-base leading-6 font-normal text-gray-900 flex items-center'>
                        Seller requests blocked
                        <Tippy content='Number of sellers that could not be retrieved. These sellers will be processed automatically on the next session and no action is necessary.'>
                          <button>
                            <SolidIcons.QuestionMarkCircle className='h-4 inline ml-1 mb-1' />
                          </button>
                        </Tippy>
                      </dt>
                      <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
                        <div className='flex items-baseline text-2xl leading-8 font-semibold text-red-600'>
                          <AnimatedNumber
                            value={sellersBlocked}
                            formatValue={(value) =>
                              numeral(value).format("0,0")
                            }
                          />
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          )}

          <ScrapesMonthlyReport />

          <ScrapesHistory />
          {/*
          Do not click:
          <button
            className='px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-600 focus:outline-none'
            onClick={() => {
              dispatch(scrapeAsync());
            }}
          >
            Scrape
          </button>
          <br />
          <button
            className='px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-600 focus:outline-none'
            onClick={() => {
              dispatch(stopScrapeAsync());
            }}
          >
            Stop
          </button>
          <br />
          <button
            className='px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-600 focus:outline-none'
            onClick={() => {
              dispatch(pauseScrapeAsync());
            }}
          >
            Pause
          </button>
          */}
        </div>
      </div>
    </main>
  );
};

export default Scraper;
