import React from "react";
import { logoutAsync } from "../features/login/loginSlice";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

const Navigation = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  return (
    <nav className='bg-gray-500'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='flex items-center justify-between h-16'>
          <div className='flex items-center'>
            <div className='hidden md:block'>
              <div className='flex items-baseline'>
                <Link
                  to='/'
                  className={`${
                    "/" === pathname ? "bg-gray-600" : ""
                  } px-3 py-2 rounded-md text-sm font-medium text-white focus:outline-none`}
                >
                  Sellers
                </Link>

                <Link
                  to='/scraper'
                  className={`${
                    "/scraper" === pathname ? "bg-gray-600" : ""
                  } px-3 py-2 rounded-md text-sm font-medium text-white focus:outline-none`}
                >
                  Scraper
                </Link>
              </div>
            </div>
          </div>
          <div className='hidden md:block'>
            <div className='ml-4 flex items-center md:ml-6'>
              <button
                type='button'
                className='inline-flex items-center px-3 py-2 mr-4 text-sm leading-4 font-medium rounded-md text-white hover:bg-gray-700 focus:outline-none transition ease-in-out duration-150'
                onClick={() => {
                  dispatch(logoutAsync());
                }}
              >
                Log out
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
