import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSellersAsync = createAsyncThunk(
  "sellers/get",
  async (payload, extra) => {
    const {
      currentPage,
      sellersPerPage,
      filters,
      sorting,
    } = extra.getState().seller;

    const response = await axios.get(`sellers`, {
      params: { currentPage, sellersPerPage, filters, sorting },
    });
    return response.data;
  }
);

export const getSellersCSVAsync = createAsyncThunk(
  "sellers/csv",
  async (payload, extra) => {
    const { filters, sorting } = extra.getState().seller;

    const response = await axios({
      url: "csv",
      method: "GET",
      responseType: "blob", // important
      params: { filters, sorting },
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "sellers.csv");
    document.body.appendChild(link);
    link.click();

    return response.data;
  }
);

export const updateSelectedSellersAsync = createAsyncThunk(
  "sellers/updateSelected",
  async (payload) => {
    const response = await axios.post(`update-selected-sellers`, payload);
    return response.data;
  }
);

export const sellerSlice = createSlice({
  name: "seller",
  initialState: {
    loading: false,
    error: null,
    sellers: [],
    categoryFilter: [],
    total: 0,
    filters: {},
    sorting: null, // { field: "name", order: "ASC|DESC"}
    currentPage: 1,
    sellersPerPage: 25,
  },
  reducers: {
    goToNextPage: (state, action) => {
      if (state.currentPage * state.sellersPerPage < state.total) {
        state.currentPage += 1;
      }
    },
    goToPrevPage: (state, action) => {
      if (state.currentPage > 1) {
        state.currentPage -= 1;
      }
    },
    setSorting: (state, action) => {
      if (!action.payload || !action.payload.field || !action.payload.order) {
        state.sorting = null;
      } else {
        state.sorting = {
          field: action.payload.field,
          order: action.payload.order,
        };
      }
    },
    setFilters: (state, action) => {
      //if ("name" === action.payload.name) {
      state.currentPage = 1;
      // }
      state.filters = {
        ...state.filters,
        [action.payload.name]: action.payload.value,
      };
    },
    toggleDetails: (state, action) => {
      state.sellers = state.sellers.map((seller) => {
        if (seller.id === action.payload) {
          seller.detailsShowing = !seller.detailsShowing;
        } else {
          seller.detailsShowing = false;
        }
        return seller;
      });
    },
  },
  extraReducers: {
    [getSellersAsync.pending]: (state) => {
      state.loading = true;
    },
    [getSellersAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.sellers = (action.payload.sellers || []).map((seller) => {
        seller.detailsShowing = false;
        return seller;
      });
      state.total = action.payload.count;
      state.categoryFilter = action.payload.categoryFilter;
    },
    [updateSelectedSellersAsync.pending]: (state) => {},
    [updateSelectedSellersAsync.fulfilled]: (state, action) => {},
  },
});

export const {
  toggleDetails,
  setFilters,
  setSorting,
  goToNextPage,
  goToPrevPage,
} = sellerSlice.actions;

export default sellerSlice.reducer;
