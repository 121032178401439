import React from "react";
import * as OutlineIcons from "../../assets/outline";
import { useSelector } from "react-redux";
import * as moment from "moment";
import * as numeral from "numeral";

const ScrapesHistory = () => {
  const { crawlSessionsList } = useSelector((state) => state.scraper);
  return (
    <div>
      <h3 className='text-lg leading-6 font-medium text-gray-900'>
        Scrapes history
      </h3>

      <div className='mt-5 bg-white shadow overflow-hidden sm:rounded-md'>
        <ul>
          {(crawlSessionsList || []).map((crawlSess, i) => {
            return (
              <li>
                <a
                  href='/'
                  className={`${
                    0 === i % 2 ? "" : "bg-gray-50"
                  } block focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out`}
                >
                  <div className='flex items-center px-4 py-4 sm:px-6'>
                    <div className='min-w-0 flex-1 flex items-center'>
                      <div className='min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4'>
                        <div>
                          <div className='text-sm leading-5 font-medium text-gray-600 truncate'>
                            {moment(crawlSess.startedAt).format(
                              "MMMM Do YYYY, h:mm a"
                            )}
                          </div>
                          <div className='mt-2 flex items-center text-sm leading-5 text-gray-500'>
                            <OutlineIcons.UserAdd className='h-3 mr-1' />
                            <span>Sellers found: </span>
                            <span className='text-green-600 ml-1'>
                              +{numeral(crawlSess.sellersCrawled).format("0,0")}
                            </span>
                          </div>
                        </div>
                        <div className='hidden md:block'>
                          <div>
                            <div className='text-sm leading-5 text-gray-900'>
                              Status
                            </div>
                            <div className='mt-2 flex items-center text-sm leading-5 text-gray-500'>
                              {"completed" === crawlSess.status ? (
                                <OutlineIcons.CheckCircle className='h-5 mr-1 text-green-500' />
                              ) : (
                                <OutlineIcons.XCircle className='h-5 mr-1 text-red-500' />
                              )}
                              {crawlSess.status}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ScrapesHistory;
