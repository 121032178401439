import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const loginAsync = createAsyncThunk("auth/login", async (payload) => {
  const response = await axios.post(`auth/login`, payload);
  return response.data;
});

export const logoutAsync = createAsyncThunk("auth/logout", async (payload) => {
  const response = await axios.post(`auth/logout`, payload);
  return response.data;
});

export const checkAuthStatusAsync = createAsyncThunk(
  "auth/status",
  async () => {
    const response = await axios.get(`auth/status`);
    return response.data;
  }
);

export const loginSlice = createSlice({
  name: "auth",
  initialState: {
    loading: true,
    error: null,
    isAuthenticated: false,
  },
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: {
    [loginAsync.pending]: (state) => {
      state.loading = true;
    },
    [loginAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      if (!action.payload.isAuthenticated) {
        state.error = "Wrong username or password";
      } else {
        state.error = null;
      }
    },
    [logoutAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
    },
    [checkAuthStatusAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
  },
});
export const { clearErrors } = loginSlice.actions;
export default loginSlice.reducer;
