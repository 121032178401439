import React from "react";
import { useSelector } from "react-redux";
import * as numeral from "numeral";

const ScrapesMonthlyReport = () => {
  const {
    sellersTotal,
    sellersTotalThisWeek,
    sellersTotalThisMonth,
  } = useSelector((state) => state.scraper);
  return (
    <div className='mb-5'>
      <h3 className='text-lg leading-6 font-medium text-gray-900'>
        Seller reports
      </h3>
      <div className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3'>
        <div className='bg-white overflow-hidden shadow rounded-lg'>
          <div className='px-4 py-5 sm:p-6'>
            <dl>
              <dt className='text-sm leading-5 font-medium text-gray-500 truncate'>
                Total Sellers
              </dt>
              <dd className='mt-1 text-3xl leading-9 font-semibold text-green-600'>
                {numeral(sellersTotal).format("0,0")}
              </dd>
            </dl>
          </div>
        </div>
        <div className='bg-white overflow-hidden shadow rounded-lg'>
          <div className='px-4 py-5 sm:p-6'>
            <dl>
              <dt className='text-sm leading-5 font-medium text-gray-500 truncate'>
                Sellers this month
              </dt>
              <dd className='mt-1 text-3xl leading-9 font-semibold text-green-600'>
                {numeral(sellersTotalThisMonth).format("0,0")}
              </dd>
            </dl>
          </div>
        </div>
        <div className='bg-white overflow-hidden shadow rounded-lg'>
          <div className='px-4 py-5 sm:p-6'>
            <dl>
              <dt className='text-sm leading-5 font-medium text-gray-500 truncate'>
                Sellers this week
              </dt>
              <dd className='mt-1 text-3xl leading-9 font-semibold text-green-600'>
                {numeral(sellersTotalThisWeek).format("0,0")}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrapesMonthlyReport;
