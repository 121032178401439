import React from "react";
import * as OutlineIcons from "../assets/outline";
import * as moment from "moment";

const SellerDetails = ({ seller }) => {
  return (
    <tr className={seller.detailsShowing ? "" : "hidden"}>
      <td colSpan='10'>
        <div className='bg-white shadow overflow-hidden sm:rounded-lg'>
          <div className='px-4 py-5 border-b border-gray-200 sm:px-6'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              {seller.name} details:
            </h3>
            {seller.logoUrl ? (
              <img src={seller.logoUrl} alt={seller.name} />
            ) : (
              "No logo"
            )}
          </div>
          <div>
            <dl>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm leading-5 font-medium text-gray-500'>
                  Legal Business Name
                </dt>
                <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
                  {seller.businessName}
                </dd>
              </div>
              <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm leading-5 font-medium text-gray-500'>
                  Categories
                </dt>
                <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
                  <ul>
                    {(seller.SellerCategories || []).map((cat) => {
                      return (
                        <li key={cat.id} className='mb-1'>
                          <b>{cat.category}</b>{" "}
                          <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-blue-100 text-blue-800'>
                            {cat.totalProducts}{" "}
                            {1 === cat.totalProducts ? "product" : "products"}
                          </span>
                          {cat.topSeller && (
                            <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800'>
                              Top seller
                            </span>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </dd>
              </div>

              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm leading-5 font-medium text-gray-500'>
                  Address
                </dt>
                <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
                  {seller.address}
                </dd>
              </div>
              <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm leading-5 font-medium text-gray-500'>
                  About
                </dt>
                <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
                  {seller.about ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: seller.about,
                      }}
                    ></div>
                  ) : (
                    "No about info"
                  )}
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm leading-5 font-medium text-gray-500'>
                  Customer service phone number
                </dt>
                <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
                  {seller.phone ? (
                    <>
                      <OutlineIcons.Phone className='h-4 mr-1 inline' />
                      {seller.phone}
                    </>
                  ) : (
                    "No phone number"
                  )}
                </dd>
              </div>
              <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm leading-5 font-medium text-gray-500'>
                  Date added
                </dt>
                <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
                  {moment(seller.createdAt).format("MMMM Do YYYY, h:mm a")}
                </dd>
              </div>
              <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm leading-5 font-medium text-gray-500'>
                  Last update
                </dt>
                <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
                  {moment(seller.updatedAt).format("MMMM Do YYYY, h:mm a")}
                </dd>
              </div>
              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm leading-5 font-medium text-gray-500'>
                  Links
                </dt>
                <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
                  <ul className='border border-gray-200 rounded-md'>
                    <li className='pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5'>
                      <div className='w-0 flex-1 flex items-center'>
                        <OutlineIcons.ExternalLink className='h-3' />
                        <span className='ml-2 flex-1 w-0 truncate'>
                          Storefront on Amazon.com
                        </span>
                      </div>
                      <div className='ml-4 flex-shrink-0'>
                        <a
                          href={`https://www.amazon.com/s?me=${seller.id}`}
                          className='font-medium text-blue-600 hover:text-blue-500 transition duration-150 ease-in-out'
                          target='blank'
                          rel='noreferrer noopener'
                        >
                          Visit site
                        </a>
                      </div>
                    </li>
                    <li className='border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5'>
                      <div className='w-0 flex-1 flex items-center'>
                        <OutlineIcons.ExternalLink className='h-3' />
                        <span className='ml-2 flex-1 w-0 truncate'>
                          Profile on Amazon.com
                        </span>
                      </div>
                      <div className='ml-4 flex-shrink-0'>
                        <a
                          href={`https://www.amazon.com/sp?_encoding=UTF8&seller=${seller.id}`}
                          className='font-medium text-blue-600 hover:text-blue-500 transition duration-150 ease-in-out'
                          target='blank'
                          rel='noreferrer noopener'
                        >
                          Visit site
                        </a>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default SellerDetails;
