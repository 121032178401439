import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFilters } from "../features/seller/sellerSlice";
import * as OutlineIcons from "../assets/outline";
import * as SolidIcons from "../assets/solid";

const CategoryFilter = () => {
  const { sellers, filters, categoryFilter, loading } = useSelector(
    (state) => state.seller
  );
  const dispatch = useDispatch();

  const [categoryMenuOpen, setCategoryMenuOpen] = useState(false);

  const categoryMenuRef = useRef(null);

  const getCategoryFilterValues = (category) => {
    if ((filters.category || []).includes(category)) {
      return filters.category.filter((id) => id !== category);
    } else {
      return (filters.category || []).concat(category);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const handleClickOutside = (event) => {
    if (
      categoryMenuRef &&
      categoryMenuRef.current &&
      !categoryMenuRef.current.contains(event.target)
    ) {
      // click outsite categoryMenuRef
      setCategoryMenuOpen(false);
    }
  };

  return (
    <div className='relative' ref={categoryMenuRef}>
      <span
        className='inline-block w-full rounded-md shadow-sm'
        onClick={() => setCategoryMenuOpen(!categoryMenuOpen)}
      >
        <button
          type='button'
          aria-haspopup='listbox'
          aria-expanded='true'
          aria-labelledby='listbox-label'
          className=' relative w-full rounded-md border bg-gray-400 pl-3 pr-10 mt-4 py-2 border-none text-left focus:outline-none transition ease-in-out duration-150'
        >
          <span style={{ maxWidth: 200 }} className='block truncate'>
            {Array.isArray(filters.category) && filters.category.length > 0
              ? filters.category.map((cat, i) =>
                  i + 1 === filters.category.length ? cat : cat + ", "
                )
              : "Filter by Category"}
          </span>
          <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
            <SolidIcons.Selector className='h-3 text-white'></SolidIcons.Selector>
          </span>
        </button>
      </span>

      {categoryMenuOpen && (
        <div
          style={{
            width: 330,
            animationName: "slideInFromTop",
            animationDuration: "0.3s",
            zIndex: 1,
          }}
          className={`${
            (sellers || []).length > 5 && !loading ? "absolute" : "fixed"
          } mt-1 w-full rounded-md bg-white shadow-lg`}
        >
          <ul
            tabIndex='-1'
            role='listbox'
            className='max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5'
          >
            {(categoryFilter || []).map((category, i) => (
              <li
                key={i}
                id='listbox-option-0'
                className='pl-4 text-gray-900 cursor-pointer select-none relative py-2 pr-9 hover:bg-gray-100'
                style={{ fontSize: "10px" }}
                onClick={() => {
                  dispatch(
                    setFilters({
                      name: "category",
                      value: getCategoryFilterValues(category.category),
                    })
                  );
                }}
              >
                <span
                  className={`${
                    (filters.category || []).includes(category.category)
                      ? "font-semibold"
                      : "font-normal"
                  } block text-left`}
                >
                  {category.category}
                </span>

                <span className='text-gray-600 absolute inset-y-0 right-0 flex items-center pr-4'>
                  {(filters.category || []).includes(category.category) && (
                    <OutlineIcons.Check className='h-6' />
                  )}
                  {/*({category.count})*/}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CategoryFilter;
