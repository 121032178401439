import React, { useEffect, useState } from "react";
import * as OutlineIcons from "../assets/outline";
import {
  toggleDetails,
  getSellersAsync,
  setSorting,
  setFilters,
  getSellersCSVAsync,
} from "../features/seller/sellerSlice";
import { useDispatch, useSelector } from "react-redux";
import SellerDetails from "./SellerDetails";
import CategoryFilter from "./CategoryFilter";
import NameFilter from "./NameFilter";
import * as numeral from "numeral";
import Loading from "./Loading";

const SellersTable = () => {
  const dispatch = useDispatch();

  const { sellers, filters, sorting, loading } = useSelector(
    (state) => state.seller
  );

  const [
    getSellerAsyncShouldBeDispatched,
    setGetSellerAsyncShouldBeDispatched,
  ] = useState(false);

  useEffect(() => {
    setGetSellerAsyncShouldBeDispatched(true);

    // eslint-disable-next-line
  }, [filters, sorting]);

  useEffect(() => {
    if (getSellerAsyncShouldBeDispatched && !loading) {
      dispatch(getSellersAsync()).then(() => {});
      setGetSellerAsyncShouldBeDispatched(false);
    }
    // eslint-disable-next-line
  }, [loading, getSellerAsyncShouldBeDispatched]);

  const sortingChanged = (event) => {
    const field = event.target.dataset.field;
    let order = "";
    if (null === sorting || (null !== sorting && field !== sorting.field)) {
      order = "ASC";
      dispatch(setSorting({ field, order }));
    } else if ("ASC" === sorting.order) {
      order = "DESC";
      dispatch(setSorting({ field, order }));
    } else if ("DESC" === sorting.order) {
      dispatch(setSorting(null));
    }
  };

  const getMainCategory = (seller) => {
    let mainCategory = { totalProducts: 0 };
    (seller.SellerCategories || []).forEach((cat) => {
      if (mainCategory.totalProducts <= cat.totalProducts) {
        mainCategory = cat;
      }
    });
    return mainCategory.category;
  };

  return (
    <div className='flex flex-col'>
      <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
        <div className='flex justify-between mb-5'>
          <h3 className='text-lg leading-6 font-medium text-gray-900'>
            Sellers
          </h3>
          <button
            type='button'
            className='inline-flex items-center px-3 py-2 text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none transition ease-in-out duration-150'
            onClick={() => dispatch(getSellersCSVAsync())}
          >
            <OutlineIcons.Download className='h-4 mr-2' />
            Get CSV
          </button>
        </div>
        {/* Tables */}
        <div className='align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200'>
          <table className='table-fixed w-full'>
            <thead>
              <tr className='bg-gray-500 text-white'>
                <th
                  className='px-6 pl-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium uppercase tracking-wider cursor-pointer select-none'
                  style={{ width: "20%" }}
                >
                  <span
                    data-field='name'
                    onClick={(event) => sortingChanged(event)}
                  >
                    Name
                  </span>
                  {sorting &&
                    "name" === sorting.field &&
                    "ASC" === sorting.order && (
                      <OutlineIcons.ChevronUp className='h-4 inline ml-1' />
                    )}
                  {sorting &&
                    "name" === sorting.field &&
                    "DESC" === sorting.order && (
                      <OutlineIcons.ChevronDown className='h-4 inline ml-1' />
                    )}
                  <NameFilter
                    filters={filters}
                    setFilters={setFilters}
                    name='name'
                  />
                </th>
                <th
                  className='px-2 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium uppercase tracking-wider text-center cursor-pointer select-none'
                  style={{ width: "10%" }}
                >
                  <span
                    data-field='productsTotal'
                    onClick={(event) => sortingChanged(event)}
                  >
                    Products
                  </span>
                  {sorting &&
                    "productsTotal" === sorting.field &&
                    "ASC" === sorting.order && (
                      <OutlineIcons.ChevronUp className='h-4 inline ml-1' />
                    )}
                  {sorting &&
                    "productsTotal" === sorting.field &&
                    "DESC" === sorting.order && (
                      <OutlineIcons.ChevronDown className='h-4 inline ml-1' />
                    )}
                </th>
                <th
                  className='px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium uppercase tracking-wider text-center cursor-pointer select-none'
                  style={{ width: "18%" }}
                >
                  Main category
                  <CategoryFilter />
                </th>
                <th
                  className='py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium uppercase tracking-wider text-center cursor-pointer select-none'
                  style={{ width: "6%" }}
                >
                  <span
                    data-field='positiveFeedbackLast12Months'
                    onClick={(event) => sortingChanged(event)}
                  >
                    Pos
                  </span>
                  {sorting &&
                    "positiveFeedbackLast12Months" === sorting.field &&
                    "ASC" === sorting.order && (
                      <OutlineIcons.ChevronUp className='h-4 inline ml-1' />
                    )}
                  {sorting &&
                    "positiveFeedbackLast12Months" === sorting.field &&
                    "DESC" === sorting.order && (
                      <OutlineIcons.ChevronDown className='h-4 inline ml-1' />
                    )}
                </th>
                <th
                  className='py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium uppercase tracking-wider text-center cursor-pointer select-none'
                  style={{ width: "6%" }}
                >
                  <span
                    data-field='neutralFeedbackLast12Months'
                    onClick={(event) => sortingChanged(event)}
                  >
                    Neu
                  </span>
                  {sorting &&
                    "neutralFeedbackLast12Months" === sorting.field &&
                    "ASC" === sorting.order && (
                      <OutlineIcons.ChevronUp className='h-4 inline ml-1' />
                    )}
                  {sorting &&
                    "neutralFeedbackLast12Months" === sorting.field &&
                    "DESC" === sorting.order && (
                      <OutlineIcons.ChevronDown className='h-4 inline ml-1' />
                    )}
                </th>
                <th
                  className=' py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium uppercase tracking-wider text-center cursor-pointer select-none'
                  style={{ width: "6%" }}
                >
                  <span
                    data-field='negativeFeedbackLast12Months'
                    onClick={(event) => sortingChanged(event)}
                  >
                    Neg
                  </span>
                  {sorting &&
                    "negativeFeedbackLast12Months" === sorting.field &&
                    "ASC" === sorting.order && (
                      <OutlineIcons.ChevronUp className='h-4 inline ml-1' />
                    )}
                  {sorting &&
                    "negativeFeedbackLast12Months" === sorting.field &&
                    "DESC" === sorting.order && (
                      <OutlineIcons.ChevronDown className='h-4 inline ml-1' />
                    )}
                </th>
                <th
                  className='px-2 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium uppercase tracking-wider text-center cursor-pointer select-none'
                  style={{ width: "9%" }}
                >
                  <span
                    data-field='feedbackCountLifetime'
                    onClick={(event) => sortingChanged(event)}
                  >
                    Feedback
                  </span>
                  {sorting &&
                    "feedbackCountLifetime" === sorting.field &&
                    "ASC" === sorting.order && (
                      <OutlineIcons.ChevronUp className='h-4 inline ml-1' />
                    )}
                  {sorting &&
                    "feedbackCountLifetime" === sorting.field &&
                    "DESC" === sorting.order && (
                      <OutlineIcons.ChevronDown className='h-4 inline ml-1' />
                    )}
                </th>

                <th
                  className='px-2 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium uppercase tracking-wider text-center'
                  style={{ width: "10%" }}
                >
                  State
                  <NameFilter
                    filters={filters}
                    setFilters={setFilters}
                    name='state'
                  />
                </th>
                <th
                  className='px-2 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium uppercase tracking-wider text-center'
                  style={{ width: "10%" }}
                >
                  City
                  <NameFilter
                    filters={filters}
                    setFilters={setFilters}
                    name='city'
                  />
                </th>
                <th
                  className='px-2 py-3 border-b border-gray-200'
                  style={{ width: "5%" }}
                ></th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200'>
              {!loading ? (
                (sellers || []).map((seller, i) => {
                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          <div className='flex items-center'>
                            <div
                              className='text-sm leading-5 font-medium text-gray-900 truncate'
                              title={seller.name}
                            >
                              {seller.name}
                            </div>
                            {seller.justLaunched && (
                              <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-yellow-100 text-yellow-800'>
                                Just launched
                              </span>
                            )}
                            {seller.topSellerSum > 0 && (
                              <span className='ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800'>
                                Top Seller
                              </span>
                            )}
                          </div>
                        </td>
                        <td className='px-2 py-4 whitespace-no-wrap border-b border-gray-200'>
                          <div className='text-sm leading-5 text-gray-600 text-center'>
                            {numeral(seller.totalProductsSum).format("0,0")}
                          </div>
                        </td>
                        <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                          <div
                            className='text-sm leading-5 text-gray-600 text-center truncate'
                            title={getMainCategory(seller)}
                          >
                            {getMainCategory(seller)}
                          </div>
                        </td>
                        <td className='px-2 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                          <div className='text-sm leading-5 text-green-600 text-center'>
                            {seller.positiveFeedbackLast12Months}%
                          </div>
                        </td>
                        <td className='px-2 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                          <div className='text-sm leading-5 text-blue-600 text-center'>
                            {seller.neutralFeedbackLast12Months}%
                          </div>
                        </td>
                        <td className='px-2 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                          <div className='text-sm leading-5 text-red-600 text-center'>
                            {seller.negativeFeedbackLast12Months}%
                          </div>
                        </td>
                        <td
                          style={{ width: 180, minWidth: 180 }}
                          className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'
                        >
                          <div className='text-sm leading-5 text-gray-600 text-center'>
                            {numeral(seller.feedbackCountLifetime).format(
                              "0,0"
                            )}
                          </div>
                        </td>
                        <td className='px-2 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                          <div className='text-sm leading-5 text-gray-600 text-center'>
                            {seller.state}
                          </div>
                        </td>
                        <td className='px-2 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                          <div className='text-sm leading-5 text-gray-600 text-center'>
                            {seller.city}
                          </div>
                        </td>
                        <td className='py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium'>
                          <span
                            onClick={() => dispatch(toggleDetails(seller.id))}
                          >
                            {seller.detailsShowing ? (
                              <OutlineIcons.ChevronUp className='h-6 text-gray-400 cursor-pointer hover:text-gray-600' />
                            ) : (
                              <OutlineIcons.ChevronDown className='h-6 text-gray-400 cursor-pointer hover:text-gray-600' />
                            )}
                          </span>
                        </td>
                      </tr>
                      <SellerDetails seller={seller} />
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan='10'>
                    <div className='py-5'>
                      <Loading />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SellersTable;
