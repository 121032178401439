import React, { useState, useEffect, useRef } from "react";
import * as OutlineIcons from "../assets/outline";
import Transition from "./utils/Transition.js";
import { useDispatch } from "react-redux";

const NameFilter = ({ filters, setFilters, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Show all");
  const dispatch = useDispatch();

  const menu = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const handleClickOutside = (event) => {
    // click outsite to close menu
    if (menu && menu.current && !menu.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  return (
    <div className='flex justify-center'>
      <input
        className='py-1 mt-4 form-input rounded-md block w-full text-sm text-white bg-gray-400 placeholder-white border-0 focus:shadow-none'
        placeholder='Search...'
        name={name}
        value={filters[name] || ""}
        onChange={(event) => {
          dispatch(
            setFilters({
              name: event.target.name,
              value: event.target.value,
            })
          );
        }}
      />

      <div className='relative inline-block text-left'>
        {/*<button
          style={{ paddingTop: 9, paddingBottom: 9 }}
          className='px-2 mt-4 rounded-r-md bg-gray-400 hover:bg-gray-300 focus:outline-none transition ease-in-out duration-150'
          onClick={() => setIsOpen(!isOpen)}
          ref={menu}
        >
          <SolidIcons.Filter className='h-3 text-white'></SolidIcons.Filter>
        </button>*/}

        <Transition
          show={isOpen}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <div className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg'>
            <div
              className='rounded-md bg-white shadow-xs'
              role='menu'
              aria-orientation='vertical'
              aria-labelledby='options-menu'
            >
              <div className='py-1'>
                <button
                  className={`${
                    "Show all" === selectedOption ? "font-bold" : ""
                  } w-full flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900`}
                  role='menuitem'
                  onClick={() => {
                    setSelectedOption("Show all");
                    setIsOpen(false);
                  }}
                >
                  <OutlineIcons.ViewList className='h-4 mr-2' />
                  Show all
                </button>
                <button
                  className={`${
                    "Only top sellers" === selectedOption ? "font-bold" : ""
                  } w-full flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900`}
                  role='menuitem'
                  onClick={() => {
                    setSelectedOption("Only top sellers");
                    setIsOpen(false);
                  }}
                >
                  <OutlineIcons.User className='h-4 mr-2' />
                  Only top sellers
                </button>
                <button
                  className={`${
                    "Only just added" === selectedOption ? "font-bold" : ""
                  } w-full flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900`}
                  role='menuitem'
                  onClick={() => {
                    setSelectedOption("Only just added");
                    setIsOpen(false);
                  }}
                >
                  <OutlineIcons.UserAdd className='h-4 mr-2' />
                  Only just added
                </button>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default NameFilter;
