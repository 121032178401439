import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  goToNextPage,
  goToPrevPage,
  getSellersAsync,
} from "../features/seller/sellerSlice";
import * as numeral from "numeral";

const TablePagination = () => {
  const dispatch = useDispatch();
  const { total, currentPage, sellersPerPage } = useSelector(
    (state) => state.seller
  );
  return (
    <div>
      <nav className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6'>
        <div className='hidden sm:block'>
          <p className='text-sm leading-5 text-gray-700'>
            Showing
            <span className='font-medium'>
              {" "}
              {0 === total ? 0 : (currentPage - 1) * sellersPerPage + 1}{" "}
            </span>
            to
            <span className='font-medium'>
              {" "}
              {(currentPage - 1) * sellersPerPage + sellersPerPage > total
                ? total
                : (currentPage - 1) * sellersPerPage + sellersPerPage}{" "}
            </span>
            of
            <span className='font-medium'>
              {" "}
              {numeral(total).format("0,0")}{" "}
            </span>
            results
          </p>
        </div>
        <div className='flex-1 flex justify-between sm:justify-end'>
          <button
            className={`${
              currentPage <= 1
                ? "text-gray-300"
                : "text-gray-700 hover:text-gray-500"
            } relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md bg-white  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`}
            onClick={async () => {
              await dispatch(goToPrevPage());
              dispatch(getSellersAsync());
            }}
            disabled={currentPage <= 1}
          >
            Previous
          </button>
          <button
            className={`${
              !(currentPage * sellersPerPage < total)
                ? "text-gray-300"
                : "text-gray-700 hover:text-gray-500"
            } ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`}
            onClick={async () => {
              await dispatch(goToNextPage());
              dispatch(getSellersAsync());
            }}
            disabled={!(currentPage * sellersPerPage < total)}
          >
            Next
          </button>
        </div>
      </nav>
    </div>
  );
};

export default TablePagination;
