import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const scrapeAsync = createAsyncThunk(
  "scraper/scrape",
  async (payload) => {
    const response = await axios.post(`scrape-new`, payload);
    return response.data;
  }
);

export const getScrapeStatusAsync = createAsyncThunk(
  "scraper/get-scrape-status",
  async (payload) => {
    const response = await axios.get(`scrape-status`, payload);
    return response.data;
  }
);

export const stopScrapeAsync = createAsyncThunk(
  "scraper/stop-scraping",
  async (payload) => {
    const response = await axios.post(`abort-scraper`, payload);
    return response.data;
  }
);

export const pauseScrapeAsync = createAsyncThunk(
  "scraper/pause-scraping",
  async (payload) => {
    const response = await axios.post(`pause-scraper`, payload);
    return response.data;
  }
);

export const scraperSlice = createSlice({
  name: "scraper",
  initialState: {
    loading: true,
    sessionRunning: undefined,
    runningSessionSellersCrawled: 0,
    sellersTotal: 0,
    sellersBlocked: 0,
    sellersSkipped: 0,
    sellersTotalThisWeek: 0,
    sellersTotalThisMonth: 0,
    crawlSessionsList: [],
    sellersNotCrawled: 0,
    sellersFound: 0,
  },
  reducers: {},
  extraReducers: {
    [getScrapeStatusAsync.fulfilled]: (state, action) => {
      state.sessionRunning = true;
      state.runningSessionSellersCrawled =
        action.payload.runningSessionSellersCrawled;
      state.sellersBlocked = action.payload.sellersBlocked;
      state.sellersSkipped = action.payload.sellersSkipped;
      state.sellersTotal = action.payload.sellersTotal;
      state.sellersTotalThisWeek = action.payload.sellersTotalThisWeek;
      state.sellersTotalThisMonth = action.payload.sellersTotalThisMonth;
      state.crawlSessionsList = action.payload.crawlSessionsList;
      state.sessionRunning = action.payload.crawlSessionsRunning;
      state.sellersNotCrawled = action.payload.sellersNotCrawled;
      state.sellersFound = action.payload.sellersFound;
      state.loading = false;
    },
  },
});

export default scraperSlice.reducer;
