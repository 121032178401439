import React, { useState } from "react";
import { loginAsync, clearErrors } from "../features/login/loginSlice";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const [state, setState] = useState({});
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.auth);
  const onChange = (event) => {
    if (error) dispatch(clearErrors());
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(loginAsync(state));
  };
  return (
    <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <h2 className='mt-6 text-center text-3xl leading-9 font-normal text-gray-900'>
          Sign in to your account
        </h2>
      </div>

      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <form onSubmit={(event) => onSubmit(event)}>
            {error && <p className='text-red-500 mb-2'>{error}</p>}
            <div>
              <label
                htmlFor='username'
                className='block text-sm font-medium leading-5 text-gray-700'
              >
                Username
              </label>
              <div className='mt-1 rounded-md shadow-sm'>
                <input
                  id='username'
                  type='text'
                  required
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                  name='username'
                  value={state.username || ""}
                  onChange={(event) => onChange(event)}
                />
              </div>
            </div>

            <div className='mt-6'>
              <label
                htmlFor='password'
                className='block text-sm font-medium leading-5 text-gray-700'
              >
                Password
              </label>
              <div className='mt-1 rounded-md shadow-sm'>
                <input
                  id='password'
                  type='password'
                  required
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                  name='password'
                  value={state.password || ""}
                  onChange={(event) => onChange(event)}
                />
              </div>
            </div>

            <div className='mt-6'>
              <span className='block w-full rounded-md shadow-sm'>
                <button
                  type='submit'
                  className='w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition duration-150 ease-in-out'
                  disabled={loading}
                >
                  Sign in
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
