import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../features/login/loginSlice";
import sellerReducer from "../features/seller/sellerSlice";
import scraperSlice from "../features/scraper/scraperSlice";

export default configureStore({
  reducer: {
    auth: loginReducer,
    seller: sellerReducer,
    scraper: scraperSlice,
  },
});
