import React, { useEffect } from "react";
import Login from "./components/Login";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthStatusAsync } from "./features/login/loginSlice";
import Sellers from "./pages/Sellers";
import Scraper from "./pages/Scraper";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navigation from "./components/Navigation";
import Loading from "./components/Loading";

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, loading } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(checkAuthStatusAsync());
    // eslint-disable-next-line
  }, []);
  return (
    <div className='App'>
      {loading ? (
        <Loading />
      ) : isAuthenticated ? (
        <Router>
          <div className='flex flex-col h-full'>
            <Navigation />

            <Switch>
              <Route exact path='/'>
                <Sellers />
              </Route>
              <Route path='/scraper'>
                <Scraper />
              </Route>
            </Switch>
          </div>
        </Router>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
