import React from "react";
import * as OutlineIcons from "../assets/outline";

const Loading = () => {
  return (
    <div className='flex justify-center items-center h-full'>
      <OutlineIcons.Spinner className='animate-spin h-12 text-gray' />
    </div>
  );
};

export default Loading;
